import React, { useState, useEffect } from "react"

import './css/contact.css'
import ContactTemplate from '../Others/contactTemplate'
import LoadScreen from '../Others/loadScreen'

const Contact = (props) => {

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(false)

        var navbar = document.getElementById('navBar')
        navbar.classList.add('non-lp') // Adds navigation class to navbar

        // Code cleanup
        return () => {
            navbar.classList.remove('non-lp')
        }

    }, [])

    return (
        <div className="content contact" onClick={ props.hideDropdown }>
            <div className="back-cont">
                <a href="/" className="back-btn"><i className="bi-house-fill"></i> Go back</a>
            </div>
            <LoadScreen isLoading={isLoading}/>
            <ContactTemplate />
        </div>

        
    )
}

export default Contact
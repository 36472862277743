import React, { useEffect, useState } from "react";
import "../../Pages/ContactUs/css/contact-phirst-serv.css";
import LoadScreen from "../../Pages/Others/loadScreen";

function PhirstServ() {



    const [ loadState, setLoadState ] = useState(false)
    
    useEffect(() => {
        setLoadState(true)

        var navbar = document.getElementById('navBar')
        navbar.classList.add('non-lp') // Adds navigation class to navbar

        // Code cleanup
        return () => {
            navbar.classList.remove('non-lp')
        }
        
    }, [])

    return (

        <>
            { loadState ? <LoadScreen/> : ''}
            <div className="phirst-serv-container">
                <iframe src="https://phirstparkhomes.freshdesk.com/support/home" className="phirst-serv-iframe"></iframe>
            </div>
        </>

    );
  }
  
export default PhirstServ;
import React, { useState, useEffect } from "react";

import './css/explore.css'
import LoadScreen from "../Others/loadScreen";

import BookTripping from "./bookTripping";


const ExplorePage = (props) => {

    const [isLoading, setIsLoading] = useState(true)

    const [ isBooking, setBooking ] = useState(false)

    useEffect(() => {
        setIsLoading(false) 
        
        const options = {
            rootMargin: "100px 0px 100px 0px",
            threshold: .25
        }

        const observer = new IntersectionObserver(entries => {
            // console.log(entries[0])
            for (let i = 0; i < entries.length; i++ ) {
        
                entries[i].target.classList.toggle("show", entries[i].isIntersecting)
                
                
            }
            entries.forEach(entry => {
                // console.log(entry)
                // entry.target.classList.toggle("show", entry.isIntersecting)
                // if (entry.isIntersecting) entry.target.classList.add("show")
            })
        }, options)

        const steps = document.querySelectorAll('.steps')

        steps.forEach(step => {
            observer.observe(step)
        })

        var navbar = document.getElementById('navBar')
        navbar.classList.add('non-lp') // Adds navigation class to navbar
        
        return () => {
            observer.disconnect()
            navbar.classList.remove('non-lp')
        }

    }, [])

    useEffect(() => {
        let bookmodal = document.getElementById('bookTrippingModal')
        if (isBooking) {
            bookmodal.classList.add("show")    
        } else {
            bookmodal.classList.remove("show")
        }
        

        let bookContent = document.getElementById('bookContent')
        if (bookContent.scrollTop != 0) bookContent.scrollTop = 0
    }, [isBooking])



    return (
        <div className="content explore" onClick={ props.hideDropdown }>
            <div className="back-cont">
                <a href="/" className="back-btn"><i className="bi-house-fill"></i> Go back</a>
            </div>
            <LoadScreen isLoading={isLoading}/>
            <div className="main">
                <div className="text-cont">
                    <h2>Explore</h2>
                    <p>Here’s a simple guide to make your PHirst buying experience<br /><i>Elevated</i>&nbsp; and &nbsp;<i>Easy.</i></p>

                    <div className="redirect-cont">
                        <a href="#guideTitle">Check out Buying Steps</a>
                        <a href="#newsCont">Read our News & Articles</a>
                    </div>

                    <a className="redirect-btn" href="#guideTitle">Tap me to scroll down</a>
                </div>
                <div className="color-bg"></div>
            </div>

            <div className="title-cont" id="guideTitle">
                <h2 className="guide-title">5 Easy Buying Steps</h2>
            </div>
            
            <div className="guide-cont" id="guideCont">

                <div className="steps step1" id="step1">
                    <div className="step-content">
                        <img className="step-header" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/01-reservation.png" alt="" loading="lazy" />
                        <img className="title-img buyer" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-buyer.png" alt="" loading="lazy" />
                        <img className="title-img buyer-alt" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-buyer-alt.png" alt="" loading="lazy"  />
                        <div className="step-lists">
                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/day1-icon.png" alt="" loading="lazy" />
                                <ul>
                                    <li>Pays reservation fee</li>
                                    <li>Brings 1 valid ID</li>
                                    <li>Completes Reservation Documents</li>
                                </ul>
                            </div>

                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/day20-icon.png" alt="" loading="lazy" />
                                <ul>
                                    <li>Brings 2 valid IDs</li>
                                    <li>Complete Buyer’s Booking Documents</li>
                                </ul>
                            </div>

                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/day30-icon.png" alt="" loading="lazy" />
                                <ul>
                                    <li>Starts downpayment</li>
                                    <li><i>Refers to official Computation Sheet for specific unit payterm</i></li>
                                </ul>
                            </div>

                            <img className="transfer-icon icon-others" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/data-transfer-icon.png" alt="" loading="lazy" />
                        </div>

                        <img className="title-img phirst" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-phirst.png" alt="" loading="lazy" />
                        <img className="title-img phirst-alt" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-phirst-alt.png" alt="" loading="lazy" />

                        <div className="step-lists">
                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/month-2-icon.png" alt="" loading="lazy" />
                                <ul>
                                    <li>Conducts pre-qualification</li>
                                </ul>
                            </div>

                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/month-3-5-icon.png" alt="" loading="lazy" />
                                <ul>
                                    <li>Assists in home loan application to financial institution (Submits Buyer’s booking Docs on behalf of buyer for financial assessment).</li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
                
                <div className="steps step2" id="step2">
                    <div className="step-content">
                        <img className="step-header" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/02-booking.png" alt=""   />
                        <img className="title-img buyer" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-buyer.png" alt=""   />
                        <img className="title-img buyer-alt" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-buyer-alt.png" alt="" />

                        <div className="step-lists">
                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/month-6-icon.png" alt=""   />
                                <ul>
                                    <li>Signs Letter of Guarantee issued by financial institution</li>
                                </ul>
                                <ul>
                                    <li>Reaches 5% downpayment</li>
                                    <li><i>Once buyer submitted complete documents</i></li>
                                </ul>
                                <img className="percent-icon icon-others" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/percent-5-icon.png" alt=""   />
                            </div>

                        </div>

                        <img className="title-img phirst" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-phirst.png" alt=""   />
                        <img className="title-img phirst-alt" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-phirst-alt.png" alt="" />

                        <div className="step-lists">
                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/month-6-icon.png" alt=""   />
                                <ul>
                                    <li>Secures Letter of Approval for qualified buyers</li>
                                </ul>
                                <img className="file-check icon-others" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/file-check-icon.png" alt=""   />
                            </div>

                            {/* <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/month-7-12-icon.png" alt=""   />
                                <ul>
                                    <li>Secures Letter of Guarantee in coordination with buyer</li>
                                    <li>See Annex B for specific unit construction schedule. Buyer may also refer to buyer’s Portal.</li>
                                </ul>
                            </div> */}

                        </div>

                    </div>
                </div>
                
                <div className="steps step3" id="step3">
                    <div className="step-content">
                        <img className="step-header" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/03-payment-completion.png" alt=""   />
                        <img className="title-img buyer" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-buyer.png" alt="" />
                        <img className="title-img buyer-alt" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-buyer-alt.png" alt="" />

                        <div className="step-lists">
                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/month-12.png" alt="" />
                                <ul>
                                    <li>Reaches full downpayment</li>
                                    <li><i>Submits latest income docs</i></li>
                                </ul>
                            </div>

                            <img className="percent-icon2 icon-others" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/percent-10-icon.png" alt=""   />
                        </div>

                    </div>
                </div>
                
                <div className="steps step4" id="step4">
                    <div className="step-content">
                        <img className="step-header" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/04-house-completion.png" alt=""   />
                        <img className="title-img buyer" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-buyer.png" alt=""   />
                        <img className="title-img buyer-alt" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-buyer-alt.png" alt="" />

                        <div className="step-lists">
                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/month-13.png" alt=""   />
                                <ul>
                                    <li>Starts home loan amortization to financial institution (30 days after Loan Takeout)</li>
                                </ul>
                            </div>

                            <img className="house-percent icon-others" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/house-percent-icon.png" alt=""   />
                        </div>

                        <img className="title-img phirst" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-phirst.png" alt=""   />
                        <img className="title-img phirst-alt" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-phirst-alt.png" alt="" />

                        <div className="step-lists">
                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/month-13.png" alt=""   />
                                <ul>
                                    <li>Takes out home loan</li>
                                </ul>
                                <div className="img-cont icon-others">
                                    <img className="house-peso" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/house-peso.png" alt="" />
                                    <img className="percent-icon-100" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/percent-icon-100.png" alt=""  />
                                </div>
                            </div>

                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/month-13-18-icon.png" alt=""   />
                                <ul>
                                    <li>Home Construction</li>
                                </ul>
                                <img className="house-hammer icon-others" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/house-hammer.png" alt="" />
                            </div>
                            
                        </div>


                    </div>
                </div>

                <div className="steps step5" id="step5">
                    <div className="step-content">
                        <img className="step-header" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/05-house-turnover.png" alt=""   />
                        <img className="title-img buyer" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-buyer.png" alt=""   />
                        <img className="title-img buyer-alt" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-buyer-alt.png" alt="" />

                        <div className="step-lists">
                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/month-14-20-icon.png" alt=""   />
                                <ul>
                                    <li>Inspects house</li>
                                    <li>Signs acceptance documents</li>
                                </ul>
                                <img className="file-check icon-others" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/file-check-icon.png" alt=""   />
                            </div>
                        </div>

                        <img className="title-img phirst" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-phirst.png" alt=""   />
                        <img className="title-img phirst-alt" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/title-phirst-alt.png" alt="" />

                        <div className="step-lists">
                            <div className="cont-list">
                                <img className="box-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/month-18-20-icon.png" alt="" />
                                <ul>
                                    <li>Sends notice of turnover</li>
                                </ul>
                            </div>

                            <img className="house-check icon-others" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/house-check-icon.png" alt=""   />
                        </div>

                    </div>
                </div>

                <div className="disclaimer-cont">
                    <small className="disclaimer">
                        ** Disclaimer **<br />All periods indicated are estimated timelines for the general guidance of the Buyer.  PHirst / Sales Agents and brokers will provide hand in hand assistance to the Buyer throughout the process.
                    </small>
                </div>

            </div>
            <div className="book-tripping">
                <a href="#" onClick={ () => {setBooking((prevState) => !prevState)} }>Book a Tripping now!</a>
            </div>

            <div className="news-cont" id="newsCont">
                <div className="title-cont">
                    <h2 className="news-title">News and Articles</h2>
                </div>
                <div className="news">
                    <h4>Century Properties Group’s PHirst rewards Olympic Double Gold Medalist Carlos Yulo with House & Lot Package</h4>
                    <hr />
                    <small>By <a href="https://www.century-properties.com/author/digital-admin/">Century Properties</a> | August 9, 2024 | Century in the News, Top News</small>
                    <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/Charles-Home-at-PHirst-Editions-Batulao-in-Nasugbu-Batangas.jpg" alt="" />
                    <div className="">
                        <p>PHirst, the First-Home brand of listed developer Century Properties Group (CPG), has announced that it will award a house and lot package to Carlos Yulo for his outstanding achievement in winning two gold medals in gymnastics at the 2024 Paris Olympics.</p>

                        <p>“On behalf of PHirst and the entire Century Properties Group, we extend our sincerest gratitude and heartfelt congratulations to Carlos Yulo for his historic double gold in the Olympics, bringing pride to the Philippines,” said Amb. Jose E.B. Antonio, Executive Chairman of CPG. “We also extend our congratulations to Cynthia Carreon, President of the Gymnastics Association of the Philippines for the stewardship and guidance of Carlos Yulo all through the years.”</p>

                        <p>CPG is presenting Yulo with a house and lot at its PHirst Editions Batulao project in Nasugbu, Batangas. The single attached home, coincidentally named Charles – translating to Carlos in Spanish – is valued at Php6.0 million.</p>

                        <p>“We at PHirst celebrate Yulo’s triumph. His story represents the journey of every Filipino toward their dreams. We are honored to offer Carlos a ‘home of many firsts’ and wish him continued success as he pursues his dreams and creates more memorable moments both on a professional and personal level,” remarked Ricky M. Celis, President of PHirst Park Homes, Inc. and Century PHirst Corporation.</p>

                        <p>“Carlos started training at the age of seven and has relentlessly pursued his dream every day since. He embodies grit and perseverance. We celebrate his remarkable achievement of winning two gold medals at the Olympics, which has not only brought glory to our nation but also inspired countless Filipinos,” said Marco R. Antonio, President and CEO of Century Properties Group.</p>

                        <p>This gesture marks the second time that CPG has awarded a house and lot to a Filipino Olympic gold medalist. In 2021, CPG also honored Hidilyn Diaz with a PHirst house and lot package for her remarkable accomplishments as the Philippines’ first-ever Olympic gold medalist in Tokyo 2020.</p>

                        <p>“We have been consistent with our desire to reward our outstanding Olympians. It started with the champion weightlifter Hidilyn Diaz who won our first-ever Olympic gold, and now we are once again delighted to gift Carlos a PHirst home, a ‘Home of Champions!’” Amb. Antonio adds. PHirst has captured the first-home buyer market with its master planned communities bearing the 4Cs: Complete and well-provisioned homes with a perimeter fence and gate, Conceptive amenities that promote a healthy and holistic lifestyle, Connected living through WiFi zones and a shuttle service, and a Convenient buying experience. PHirst currently has 23 communities and counting spread out in key growth areas in Luzon and Visayas. For more information visit the official <a href="https://century-phirst.com/" target="_blank">website</a> and its <a href="https://www.facebook.com/PHirstOfficialFacebookPage" target="_blank">Facebook</a> page.</p>

                    </div>
                    <h6><a href="https://www.century-properties.com/cpg-rewards-carlos-yulo/" target="_blank">Read the original article here</a></h6>
                </div>
                <div className="news">
                    <h4>CENTURY PROPERTIES’ PHIRST EXPANDS PRODUCT LINES Launches PHirst Editions Batulao, its first mid-tier community</h4>
                    <hr />
                    <small>By <a href="https://www.century-properties.com/author/digital-admin/">Century Properties</a> | November 2nd, 2022 | Century in the News, Top News</small>
                    <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/PHirst-Editions-Batulao-Iconic-Image-2.jpg" alt="" />
                    <div className="">
                        <p>PHirst, Century Properties Group’s (CPG) new and expanded horizontal housing brand will cater to different market segments of first-home buyers to address the strong housing demand in the Philippines.</p>

                        <p>“We created the PHirst brand to introduce new house packages at different price points to give buyers more options in owning their PHirst home,” PHirst president Ricky M. Celis said.</p>

                        <p>PHirst’s project portfolio will cover segments from socialized housing with units priced at P580,000, economic housing with units ranging from P800,000 to P1.7 Million, affordable housing with units ranging from P1.7 Million to 3.2 Million and middle income, priced from P3.2 Million to P6 Million.</p>

                        <p>“PHirst’s product diversification allows it take part in providing quality property developments to a broad range of first-time homebuyers who wish to celebrate their life milestones by investing in their very own home,” Century Properties President and Chief Executive Officer Marco R. Antonio said</p>

                        <p>PHirst’s maiden middle-income development is PHirst Editions Batulao, a horizontal residential project in Nasugbu, Batangas. Located adjacent to the existing PHirst Park Homes Batulao community, PHirst Editions Batulao spans 14 hectares and will house 629 units with a projected sales value of P3.1 Billion.</p>

                        <p>“PHirst Editions Batulao is our answer to the strong demand for varied home suburban options and will bear the PHirst stamp of quality that many of our homebuyers have already put their trust in,” Celis said.</p>

                        <p>PHirst Editions Batulao will offer single attached & single detached units and come in four models – Cartland 54 (P3.5 Million), Charles 70 (P4.3 Million), Christie 90 (P5.8 Million), and Corin 105 (P 7.0 Million). The project’s lot sizes range from 88 to 132 square meters with floor sizes ranging from 54 to 105 square meters.</p>

                        <p>In terms of amenity and recreation offerings, PHirst Editions Batulao will offer conceptive & unique amenities that the community can enjoy. Residents can expect a clubhouse and an amenity area that will offer hammocks, a bike trail, an outdoor cinema, a rope bridge, and a pet park with WiFi coverage.</p>

                        <p>Like other PHirst projects, PHirst Editions Batulao introduces its signature brand pillars called the 4Es – Expand your vision to new horizons. Awaken your senses with these Exceptional homes. Connect with Ease to life’s conveniences and revel in an Elevated living experience.</p>

                        <p>As other brands will soon be introduced, PHirst Park Homes, which has launched 12 projects to date, shall remain an affordable housing brand.
                        </p>
                    </div>
                    <h6><a href="https://www.century-properties.com/century-properties-phirst-expands-product-lines/" target="_blank">Read the original article here</a></h6>
                </div>
                <div className="news">
                    <h4>Century Properties launches PHirst Editions Batulao</h4>
                    <hr />
                    <small>November 10, 2022</small>
                    <div className="">
                        <p>PHIRST, Century Properties Group's (CPG) new and expanded horizontal housing brand will cater to different market segments of first-home buyers to address the strong housing demand in the Philippines.</p>

                        <p>"We created the PHirst brand to introduce new house packages at different price points to give buyers more options in owning their PHirst home," PHirst President Ricky Celis said.</p>

                        <p>PHirst's project portfolio will cover segments from socialized housing, affordable housing and middle-income bracket.</p>

                        <p>"PHirst's product diversification allows it to take part in providing quality property developments to a broad range of first-time homebuyers who wish to celebrate their life milestones by investing in their very own home," Century Properties President and Chief Executive Officer Marco Antonio said.</p>

                        <p>PHirst's maiden middle-income development is PHirst Editions Batulao, a horizontal residential project in Nasugbu, Batangas. Located adjacent to the existing PHirst Park Homes Batulao community, PHirst Editions Batulao spans 14 hectares and will house 629 units with a projected sales value of P3.1 billion.</p>

                        <p>"PHirst Editions Batulao is our answer to the strong demand for varied home suburban options and will bear the PHirst stamp of quality that many of our homebuyers have already put their trust in," Celis said.</p>

                        <p>As other brands will soon be introduced, PHirst Park Homes, which has launched 12 projects to date, shall remain an affordable housing brand.</p>
                    </div>
                    <h6><a href="https://www.manilatimes.net/2022/11/10/public-square/century-properties-launches-phirst-editions-batulao/1865676" target="_blank">Read the original article here</a></h6>
                </div>
                <div className="news">
                    <h4>Unveil your life's best chapter at PHirst Editions Batulao</h4>
                    <hr />
                    <small>By Aaron Ronquillo | January 31, 2023</small>
                    <div className="">
                        <p>A LOT of people tend to have homes built outside the city. They would like to have a place from where they can escape the congestion and pollution of the urban jungle to seek tranquility and breathe in the fresh air. The province of Batangas is one of those provinces where people come to take in the clean fresh air and soak up the sun on its beaches.</p>

                        <p>Realizing this, real estate developers have been building communities in the province to give prospective homeowners access to fresh air and peace of mind they seek.</p>

                        <p>PHirst Editions, the newest project under Century Properties Group's PHirst brand, starts 2023 with a proverbial bang as it unveils its first-ever mid- to high-tier horizontal residential development in Batulao in Batangas.</p>

                        <p>PHirst Editions is being offered to first-home buyers by offering them exceptional, grander homes and an elevated living experience all without compromising quality, a hallmark of the PHirst brand.</p>

                        <p>"Batulao is a go-to place for both relaxation and adventure because of its proximity to Tagaytay, the beaches of Batangas and, of course, our famous Mount Batulao. It is also the reason why PHirst chose to put their newest and first-ever mid/high-affordable development here," Gretchen Ramos, PHirst sales head, shared in her speech during the project's official launch on Dec. 10, 2022.</p>

                        <p>The launching is characterized as a day of "chill, conquer and commune" to guests. This featured a bazaar, games and workshops reminiscent of the series of weekend festivities before the grand launch. The unveiling ceremony, hosted by PHirst brand ambassador Robi Domingo with co-host Zara Carbonell, kicked off in the afternoon with an opening performance by the Power Impact Dancers.</p>

                        <p>The program was also livestreamed in the United Arab Emirates, Taiwan and Singapore to attract overseas Filipino workers. A mini-concert capped off the day with live performances from Christian Bautista, Kelvin Silvestre, Blue is Yellow and Nicole Asensio.</p>
                        
                        <p>A PHirst of its kind</p>
                        
                        <p>PHirst Editions will feature 629 single-attached or single-detached homes, which come in four models named after famous modern literary authors — Cartland 54, Charles 70, Christie 90 and Corin 120. The typical lot areas range from 88 to 132 square meters (sqm) and floor areas from 54 to 120 sqm.</p>

                        <p>"Imagine a low-dense community of walkable, gentle terrain of greens and fog," Ramos added.</p>

                        <p>Like other projects of Century Properties, PHirst Editions Batulao has conceptive and unique amenities residents can enjoy. The property saw the addition of a Paw Park — a first in its PHirst communities; the Panorama Square, an amphitheater-type outdoor cinema; a clubhouse and an outdoor Wi-Fi nook, among others.</p>

                        <p>"Whether it is for your primary home or investment, this is the most value for money that you can get hold of in this area," Ramos said.</p>

                        <p>The PHirst Editions is a start of a new chapter for Filipinos looking to start a new journey in their lives. PHirst Editions Batulao is intended for homebuyers considering close-to-nature location for those seeking to escape the hustle and bustle of the metropolis but not too far from it. PHirst Editions Batulao promises a secured community, world-class amenities and an elevated living experience.</p>
                    </div>
                    <h6><a href="https://www.manilatimes.net/2023/01/31/business/real-estate-and-property/unveil-your-lifes-best-chapter-at-phirst-editions-batulao/1876504" target="_blank">Read the original article here</a></h6>
                </div>
            </div>

            <BookTripping toggleBook={() => {setBooking((prevState) => !prevState)}} />

        </div>
    )
}



export default ExplorePage
import React, { useEffect, useState } from "react"
import '../assets/css/about.css'

import { Link } from "react-router-dom"

import LoadScreen from "../../Others/loadScreen"

const CardBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/about-us-page/house-card-bg.jpg'
const CardLogo = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/about-us-page/house-card-logo.png'

const InfoCardBg1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/about-us-page/Model-units-02.jpg'
const InfoCardBg2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/about-us-page/Sales-pav-front.jpg'



const About = (props) => {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(false)

        var navbar = document.getElementById('navBar')
        navbar.classList.add('non-lp') // Adds navigation class to navbar

        // Code cleanup
        return () => {
            navbar.classList.remove('non-lp')
        }
        
    }, [])

    return (
        <div className="content about" onClick={ props.hideDropdown}>
            <div className="back-cont">
                <a href="/" className="back-btn"><i className="bi-house-fill"></i> Go back</a>
            </div>
        <LoadScreen isLoading={isLoading}/>
            <div className="about-container">
            {/* <div className="about-container" style={{ backgroundImage: "url(" + City + ")"}}> */}

                <div className="section2 desktop">
                    <div className="text-header">
                        <h2>About Us</h2>
                    </div>
                    <div className="info-container">
                        <InfoCard1 />
                        <InfoCard2 />
                    </div>
                </div>

                <div className="section2 mobile">
                    <div className="text-header">
                        <h2>About Us</h2>
                    </div>
                    <div className="info-container">
                        <InfoCardMobile imgLink={ InfoCardBg1 } title="All About PHirst Editions" id="card1" content="You deserve a dignified home that will last for many years. As PHirst seeks to address the essential goal to provide quality homes for all market segments, the company expands its portfolio to provide more options for Filipinos. Enter PHirst Editions, the second of many brands to come from PHirst. PHirst Editions provides dwelling options to mid to high-income earners. Expect bigger homes, an exquisite new design, and more features that will truly show your triumphs in life. At PHirst Editions, we provide not just a home, but also an adventure." quote="Unveil your life's best with PHirst Editions"/>
                        <InfoCardMobile imgLink={ InfoCardBg2 } title="PHirst Editions Batulao" id="card2" content="Reach new heights at PHirst Editions Batulao! First of the many upcoming PHirst Editions development is at Batulao, Batangas. Situated near majestic Mt. Batulao, feel the peak of success while expanding your vision to new horizons. Enjoy the fresh air, the magic of nature, and the peaceful environment that Batulao has to offer." quote="Living your best life at PHirst is truly an elevated experience."/>
                    </div>
                </div>

                <div className="section1">
                    <div className="text-header">
                        <h2>Our Brand Pillars</h2>
                        <p>It's time to bring life to the next stage, a new edition. At PHirst Editions, expect the exceptional as we live by the 4Es pillars that ensure that you truly live the best life that you deserve.</p>
                    </div>
                    <div className="card-container">
                        <AboutCard card="card1" />
                        <AboutCard card="card2" />
                        <AboutCard card="card3" />
                        <AboutCard card="card4" />
                    </div>
                </div>

            </div>
        </div>
    )
}
// 4Es Cards
const AboutCard = (props) => {
    let cardText 

    switch (props.card) {
        case "card1":
            cardText = <Card1 />
            break;
        case "card2":
            cardText = <Card2 />
            break;
        case "card3":
            cardText = <Card3 />
            break;
        case "card4":
            cardText = <Card4 />
            break;
        default:
            break;
    }

    return (
        <div className="card" style={{ backgroundImage: "url(" + CardBg + ")"}}>
            <img src={ CardLogo } alt="" />
                { cardText }
            <div className="color-bg"></div>
        </div>
    )
}

const Card1 = () => {
    return <p><b>Expand</b> <br /> your <br /> vision <br /> to <br /> New Horizons</p>
}

const Card2 = () => {
    return <p>Awaken <br /> your <br /> senses <br /> with these <br /> <b>Exceptional</b> <br /> Homes</p>
}

const Card3 = () => {
    return <p>Connect <br /> with <br /> <b>Ease</b> <br /> to Life's <br /> Conveniences</p>
}

const Card4 = () => {
    return <p>Revel <br /> in an <br /> <b>Elevated</b> <br /> living <br /> Experience</p>
}

const InfoCardMobile = (props) => {
    const [ isCard2, setCard2 ] = useState()

    useEffect(()=> {
        if (props.id === "card2") {
            setCard2(true)
        }
    }, [])
    return (
        <>
            <div className="info-card-mobile">
                <img className="card-img" src={ props.imgLink } alt="" />
                <div className="text-content">
                    <h4>{ props.title }</h4>
                    <p>{ props.content }</p>
                    <i>{ props.quote }</i>
                    <br />
                    { isCard2 ? <Link className="more-info" to="/vicinity-map">Go to Vicinity Map</Link> : "" }
                </div>
            </div>
        </>
    )
}

// About Us
const InfoCard1 = () => {
    return(
        <div className="info-card">
            <div className="img-bg" style={{ backgroundImage: "url(" + InfoCardBg1 + ")"}}></div>
            <div className="text-content">
                <h4>All About PHirst Editions</h4>
                <p>You deserve a dignified home that will last for many years. As PHirst seeks to address the essential goal to provide quality homes for all market segments, the company expands its portfolio to provide more options for Filipinos. Enter PHirst Editions, the second of many brands to come from PHirst. PHirst Editions provides dwelling options to mid to high-income earners. Expect bigger homes, an exquisite new design, and more features that will truly show your triumphs in life. At PHirst Editions, we provide not just a home, but also an adventure.</p>

                <h6>Unveil your life's best with PHirst Editions</h6>

                {/* <a href="#" className="more-info">More Info</a> */}
            </div>
        </div>
    )
}

const InfoCard2 = () => {
    return(
        <div className="info-card">
            <div className="img-bg" style={{ backgroundImage: "url(" + InfoCardBg2 + ")"}}></div>
            <div className="text-content">
                <h4>PHirst Editions Batulao</h4>
                <p>Reach new heights at PHirst Editions Batulao! First of the many upcoming PHirst Editions development is at Batulao, Batangas. Situated near majestic Mt. Batulao, feel the peak of success while expanding your vision to new horizons. Enjoy the fresh air, the magic of nature, and the peaceful environment that Batulao has to offer. </p>

                <h6>Living your best life at PHirst is truly an elevated experience.</h6>
                
                <Link className="more-info" to="/vicinity-map">Vicinity Map</Link>
                
            </div>
        </div>
    )
}

export default About
import React, { useState, useEffect, useRef} from "react";

import '../css/sales-pav.css'
import LoadScreen from "../../Others/loadScreen";
import { useCallback } from "react";

import { Link } from 'react-router-dom';

import BookTripping from "../../ExplorePage/bookTripping";

const SalesPav = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [ isBooking, setBooking ] = useState(false)

    const [ imageUrlHolder, setHolder ] = useState({ imageUrl1: "", imageUrl2: "", imageUrl3: "" })
    const carouselCounter = useRef(3)

    const [ isAnimatingExpand, setAnimatingExpand ] = useState(false)
    const [ isAnimatingExceptional, setAnimatingExceptional ] = useState(false)
    const [ isAnimatingEase, setAnimatingEase ] = useState(false)
    const [ isAnimatingElevated, setAnimatingElevated ] = useState(false)
    const [ animName, setAnimName ] = useState('')
    useEffect(() => {
        setIsLoading(false)

        let interval
        const carouselObserver = new IntersectionObserver((entries) => {
            let entry = entries[0]
            if (entry.isIntersecting) {
                clearInterval(interval)
                interval = setInterval(()=> {
                    scrollRight()
                    removeOldImage()
                }, 5000)
            } else {
                clearInterval(interval)
            }
        }, {
            threshold: .7,
            rootMargin: "40px"
        })
        carouselObserver.observe(document.getElementById("scrollingCarousel"))


        const lastCarouselItemObserver = new IntersectionObserver((entries) => {
            let lastItem = entries[0]   
            if (lastItem.isIntersecting) {
                if (carouselCounter.current > 5) {
                    carouselCounter.current = 0
                }
                loadNewImage(carouselCounter.current)
                lastCarouselItemObserver.unobserve(lastItem.target)
                lastCarouselItemObserver.observe(document.querySelector(".carousel-item:last-child"))  
                carouselCounter.current = carouselCounter.current + 1       
                // console.log(carouselCounter)
            }
        }, {
            root: document.getElementById("scrollingCarousel"),
            rootMargin: "20px"
        })
        lastCarouselItemObserver.observe(document.querySelector(".carousel-item:last-child"))


        const infoCardObserver = new IntersectionObserver((entries) => {
            let entry = entries[0]
            let card = document.getElementById("infoCard")
            card.classList.toggle("show", entry.isIntersecting)
        }, {
            threshold: .5,
            rootMargin: "-40px"
        })

        infoCardObserver.observe(document.getElementById("infoSection"))


        if (window.innerWidth <= 578) {
            setHolder((prevState) => {
                return {
                    ...prevState,
                    imageUrl1: imageList.urlMobile[0],
                    imageUrl2: imageList.urlMobile[1],
                    imageUrl3: imageList.urlMobile[2],
                }
            })
        } else if (window.innerWidth > 578) {
            setHolder((prevState) => {
                return {
                    ...prevState,
                    imageUrl1: imageList.url[0],
                    imageUrl2: imageList.url[1],
                    imageUrl3: imageList.url[2],
                }
            })
        }

        let expandTimeout = setTimeout(() => {
            var animExpandGif = document.querySelector('#animExpand')
            animExpandGif.setAttribute('src','https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/4Es-animation/expand-v2.gif')
        }, 2000)
        let exceptionalTimeout = setTimeout(() => {
            var animExceptionalGif = document.querySelector('#animExceptional')
            animExceptionalGif.setAttribute('src','https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/4Es-animation/exceptional-v2.gif')
        }, 6000)

        let easeTimeout = setTimeout(() => {
            var animEaseGif = document.querySelector('#animEase')
            animEaseGif.setAttribute('src','https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/4Es-animation/ease-v2.gif')
        }, 10400)

        let elevatedTimeout = setTimeout(() => {
            var animElevatedGif = document.querySelector('#animElevated')
            animElevatedGif.setAttribute('src','https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/4Es-animation/elevated-v2.gif')
        }, 13400)

        var navbar = document.getElementById('navBar')
        navbar.classList.add('non-lp') // Adds navigation class to navbar

        return () => {
            clearInterval(expandTimeout)
            clearInterval(exceptionalTimeout)
            clearInterval(easeTimeout)
            clearInterval(elevatedTimeout)

            carouselObserver.disconnect()
            lastCarouselItemObserver.disconnect()
            infoCardObserver.disconnect()

            navbar.classList.remove('non-lp')
        }
    }, [])


    useEffect(() => {
        let bookmodal = document.getElementById('bookTrippingModal')
        if (isBooking) {
            bookmodal.classList.add("show")    
        } else {
            bookmodal.classList.remove("show")
        }

        let bookContent = document.getElementById('bookContent')
        if (bookContent.scrollTop != 0) bookContent.scrollTop = 0
    }, [isBooking])

    useEffect(() => {
        if (isAnimatingExpand) {
            rerunAnim()
        }
    }, [isAnimatingExpand])

    useEffect(() => {
        if (isAnimatingExceptional) {
            rerunAnim()
        }
    }, [isAnimatingExceptional])
    
    useEffect(() => {
        if (isAnimatingEase) {
            rerunAnim()
        }
    }, [isAnimatingEase])
    
    useEffect(() => {
        if (isAnimatingElevated) {
            rerunAnim()
        }
    }, [isAnimatingElevated])
    
    
    let imageList = {
        url: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/carousel/carousel-image-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/carousel/carousel-image-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/carousel/carousel-image-3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/carousel/carousel-image-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/carousel/carousel-image-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/carousel/carousel-image-6.jpg",
        ],
        urlMobile: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/carousel/carousel-image-1-mobile.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/carousel/carousel-image-2-mobile.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/carousel/carousel-image-3-mobile.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/carousel/carousel-image-4-mobile.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/carousel/carousel-image-5-mobile.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/carousel/carousel-image-6-mobile.jpg",
        ]
    }

    const removeOldImage = () => {
        setTimeout(() => {
            let carouselFirst = document.querySelector(".carousel-item:first-child")
            carouselFirst.remove()
        }, 1001)
    }

    const loadNewImage = (index) => {
        let imageUrl = imageList.url[index]
        let imageUrlMobile = imageList.urlMobile[index]
        let url = ""
        if (window.innerWidth <= 578) {
            url = imageUrlMobile
        } else if (window.innerWidth > 578) {
            url = imageUrl
        }

        let carouselContainer = document.getElementById("scrollingCarousel")
        let carouselItem = document.createElement("div")
        carouselItem.classList.add("carousel-item")
    
        let itemImage = document.createElement("img")
        itemImage.setAttribute("src", url)
        itemImage.setAttribute("draggable", "false")
    
        carouselItem.append(itemImage)
        carouselContainer.appendChild(carouselItem)
    }

    const pageScroll = (e) => {
        var salesCont = document.getElementById('salesCont')
        let val = salesCont.scrollTop

        var famCont = document.getElementById('familyCont')
        famCont.style.transform = "translateY(" + val * .2 +"px)"

        var logoCont = document.getElementById('logoCont')
        logoCont.style.transform = "translateX(" + val * -.2 +"px)"

        var bgImg = document.getElementById('bgImage')
        bgImg.style.transform = "translateY(" + val * -.2 +"px)"
    }

    const scrollRight = () => {
        let carouselFirstChild = document.querySelector(".carousel-item:first-child")
        carouselFirstChild.style.maxWidth = "0px"
    }

    let gif = {
        link: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/4Es-animation/expand-v2.gif",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/4Es-animation/exceptional-v2.gif",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/4Es-animation/ease-v2.gif",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/4Es-animation/elevated-v2.gif"
        ]
    }

    const rerunAnim = () => {
        var animID, animSrc
        switch (animName) {
            case "expand":
                animID = "#animExpand"
                animSrc = gif.link[0]
                break
            case "exceptional":
                animID = "#animExceptional"
                animSrc = gif.link[1]
                break
            case "ease":
                animID = "#animEase"
                animSrc = gif.link[2]
                break
            case "elevated":
                animID = "#animElevated"
                animSrc = gif.link[3]
                break
            default:
                break
        }

        var animGif = document.querySelector(animID)
        animGif.removeAttribute('src')
        setTimeout(() => {
            animGif.setAttribute('src', animSrc)
        }, 400)
    }
    

    return (
        <>
            <div className="content sales-pav" onScroll={ (e) => { pageScroll(e) }} id="salesCont">
                <div className="back-cont">
                    <a href="/" className="back-btn"><i className="bi-house-fill"></i> Go back</a>
                </div>
                <LoadScreen isLoading={isLoading}/>
                <div className="sales-pav-main">
                    <div className="anim-cont">
                        <Link to='/corin'>
                            <div className="anim-item"
                                onMouseEnter={ () => {
                                    setAnimatingExpand(true)
                                    setAnimName("expand")
                                }}
                                onMouseLeave={ () => {
                                    setTimeout( () => {setAnimatingExpand(false)}
                                    , 4000)
                                }}
                            >
                                <img id="animExpand" src='' alt=""/>
                            </div>
                        </Link>
                        <Link to='/explore'>
                            <div className="anim-item"
                                onMouseEnter={ () => {
                                    setAnimatingExceptional(true)
                                    setAnimName("exceptional")
                                }}
                                onMouseLeave={ () => {
                                    setTimeout( () => {
                                        setAnimatingExceptional(false)
                                    }, 4000)}
                                }
                            >
                                <img id="animExceptional" src='' alt=""/>
                            </div>
                        </Link>
                        <Link to='/vicinity-map'>
                            <div className="anim-item"
                                onMouseEnter={ () => {
                                    setAnimatingEase(true)
                                    setAnimName("ease")
                                }}
                                onMouseLeave={ () => {
                                    setTimeout( () => {setAnimatingEase(false)
                                    }, 4000)
                                }}
                            >
                                <img id="animEase" src='' alt=""/>
                            </div>
                        </Link>
                        <Link to='/amenities'>
                            <div className="anim-item"
                                onMouseEnter={ () => {
                                    setAnimatingElevated(true)
                                    setAnimName("elevated")
                                }}
                                onMouseLeave={ () => {
                                setTimeout( () => {
                                    setAnimatingElevated(false)
                                }, 4000)
                                }
                            }>
                                <img id="animElevated" src='' alt=""/>
                            </div>
                        </Link>
                    </div>


                    <div className="logo-cont" id="logoCont">
                        <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/phirst-logo.png" alt="" draggable="false" />
                    </div>
                    <div className="family-cont" id="familyCont">
                        <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/family.png" alt="" draggable="false"/>
                    </div>
                    <div className="hand-rails">
                        <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/hand-rail.png" alt="" />
                    </div>
                    <img className="bg-image" id="bgImage" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/sales-pav-main-bg-alt.jpg" alt="" loading="eager"/>
                </div>
                <div className="sales-pav-carousel" id="salesPavCarousel">
                    <div className="text-content">
                        <div>
                            <h2>Welcome to your life’s latest edition! </h2>
                            <p>Achievements in life are the result of years of patience and hard work. Now, you’ll finally be able to reach newer heights and unveil life’s best.</p>
                            <p>Live the life you truly deserve with the house of your dreams at PHirst Editions!</p>
                        </div>
                    </div>  
                    <div className="scrolling-carousel" id="scrollingCarousel">
                        <div className="carousel-item">
                            <img src={ imageUrlHolder.imageUrl1 } alt="" draggable="false"/>
                        </div>
                        <div className="carousel-item">
                            <img src={ imageUrlHolder.imageUrl2 } alt="" draggable="false"/>
                        </div>
                        <div className="carousel-item">
                            <img src={ imageUrlHolder.imageUrl3 } alt="" draggable="false"/>
                        </div>
                    </div>
                </div>

                <div className="info-section" id="infoSection">
                    <div className="info-img">
                        <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/sales-pav/Front Desk 2.jpg" alt=""/>
                    </div>
                    <div className="info-card" id="infoCard">
                        <h2>Front Desk</h2>
                        <div className="btn-cont">
                            <a href="#" onClick={ () => {setBooking((prevState) => !prevState)} }>Book a Tripping</a>
                            <Link to="/vicinity-map">Vicinity Map</Link>
                            <Link to="/contact-us">Contact Us</Link>
                            <Link to="/explore">Buying Guide</Link>
                            <Link to="/about">About Us</Link>
                        </div>
                    </div>
                </div>
                <BookTripping toggleBook={() => {setBooking((prevState) => !prevState)}} />
            </div>
        </>
    )
}

export default SalesPav

